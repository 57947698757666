import { http } from "../utils/http";

interface userType extends Promise<any> {
  svg?: string;
  code?: number;
  info?: object;
}

// 获取验证码
export const getVerify = (): userType => {
  return http.request("get", "/captcha");
};

// 登录
export const getLogin = (data: object) => {
  return http.request("post", "/login", { data });
};

// 刷新token
export const refreshToken = (data: object) => {
  return http.request("post", "/refreshToken", { data });
};

// 修改密码
export const password = (data: object) => {
  return http.request("post", "/klond-acquirer-api/User/password", { data });
};
// 新用户或超时修改密码
export const firstLogin = (data: object) => {
  return http.request(
    "post",
    "/klond-acquirer-api/common/password",
    {
      data
    }
    // {
    //   withCredentials: false
    // }
  );
};

// export const searchVague = (data: object) => {
//   return http.request("post", "/searchVague", { data });
// };
