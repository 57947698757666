import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const fileRouter = {
  path: "/file",
  component: Layout,
  redirect: "/file/admin",
  meta: {
    icon: "file1",
    title: $t("menus.file"),
    rank: 33,
    authority: [
      "acquirer.export",
      "acquirer.op.file.config",
      "acquirer.op.file.row"
    ]
  },
  children: [
    {
      path: "/file/admin",
      name: "fileTitle",
      component: () => import("/@/views/file-admin/index.vue"),
      meta: {
        // icon: "file1",
        title: $t("file.fileExport"),
        showParent: true,
        authority: ["acquirer.export1.page"]
      }
    },
    {
      path: "/file/import",
      name: "fileImport",
      component: () => import("/@/views/file-admin/import.vue"),
      meta: {
        // icon: "file2",
        title: $t("file.fileImport"),
        showParent: true,
        authority: ["acquirer.export2.page"]
      }
    },
    {
      path: "/file-auto/config",
      name: "fileConfig",
      component: () => import("/@/views/file-auto/file-config/index.vue"),
      meta: {
        // icon: "file3",
        title: $t("文件配置"),
        showParent: true,
        authority: ["acquirer.op.file.config"]
      },
      children: [
        {
          path: "/file-auto/config/add",
          name: "fileConfigAdd",
          component: () => import("/@/views/file-auto/file-config/add.vue"),
          meta: {
            title: $t("编辑"),
            keepAlive: true,
            showLink: false
          }
        }
      ]
    },
    {
      path: "/fifile-autole/chase",
      name: "fileChase",
      component: () => import("/@/views/file-auto/file-chase/index.vue"),
      meta: {
        // icon: "file4",
        title: $t("文件追踪"),
        showParent: true,
        authority: ["acquirer.op.file.row"]
      }
    }
  ]
};

export default fileRouter;
