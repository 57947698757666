import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const terminalMgrRouter = {
  path: "/terminal-mgr",
  component: Layout,
  redirect: "/terminal-mgr/terminal-mgr",
  meta: {
    icon: "terminal",
    title: $t("menus.terminal"),
    rank: 6,
    authority: ["acquirer.terminal", "acquirer.serial", "acquirer.qrcode.1"]
  },
  children: [
    {
      path: "/equip-register/index",
      name: "EquipRegister",
      component: () => import("/@/views/equip-register/index.vue"),
      meta: {
        // icon: "register-admin",
        title: $t("入库登记"),
        keepAlive: true,
        showLink: true,
        authority: ["acquirer.serial"]
      }
    },
    {
      path: "/terminal-mgr/terminal-mgr",
      name: "TerminalMgr",
      component: () => import("/@/views/terminal-mgr/index.vue"),
      meta: {
        // icon: "terminal",
        title: $t("menus.terminalMgr"),
        keepAlive: true,
        showLink: true,
        authority: ["acquirer.terminal"]
      }
    },
    {
      path: "/terminal-mgr/terminal-mgr-add",
      name: "TerminalMgrAdd",
      component: () => import("/@/views/terminal-mgr/add.vue"),
      meta: {
        title: $t("新增终端"),
        keepAlive: true,
        showLink: false,
        authority: ["acquirer.terminal", "acquirer.terminal.add"]
      }
    },
    {
      path: "/terminal-mgr/terminal-mgr-edit",
      name: "TerminalMgrEdit",
      component: () => import("/@/views/terminal-mgr/add.vue"),
      meta: {
        title: $t("编辑终端"),
        keepAlive: true,
        showLink: false,
        authority: ["acquirer.terminal", "acquirer.terminal.add"]
      }
    },
    {
      path: "/terminal-mgr/terminal-mgr-detail/:terminalId",
      name: "TerminalMgrDetail",
      component: () => import("/@/views/terminal-mgr/detail.vue"),
      meta: {
        title: $t("终端详情"),
        keepAlive: true,
        showLink: false,
        authority: ["acquirer.terminal", "acquirer.terminal.detail"]
      }
    },
    {
      path: "/code-manage/index",
      name: "CodeManage",
      component: () => import("/@/views/code-manage/index.vue"),
      meta: {
        // icon: "code-manage",
        title: $t("qr.qrTitle"),
        keepAlive: true,
        showLink: true,
        authority: ["acquirer.qrcode.1"]
      },
      children: [
        {
          path: "/code-manage/index/detail",
          name: "CodeManageDetail",
          component: () => import("/@/views/code-manage/detail.vue"),
          meta: {
            title: $t("批次管理"),
            keepAlive: true,
            showLink: false,
            authority: ["acquirer.qrcode.1"]
          }
        }
      ]
    }
  ]
};

export default terminalMgrRouter;
