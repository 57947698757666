import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

// 注意：数据看板后端没有权限控制，后端返回code为空也会展示该菜单
// hasAuth：前端控制该路由是否显示;
// authority：根据后端返回code控制是否显示路由。
const homeRouter = {
  path: "/",
  name: "home",
  component: Layout,
  redirect: "/data-board",
  meta: {
    icon: "data-board",
    title: $t("menus.data"),
    rank: 0,
    hasAuth: true
  },
  children: [
    {
      path: "/data-board",
      name: "DataBoard",
      component: () => import("/@/views/data-board/index.vue"),
      meta: {
        // icon: "data-board",
        title: $t("menus.hshome"),
        hasAuth: true
      }
    },
    {
      path: "/data-report/business-report",
      name: "BusinessReport",
      component: () => import("/@/views/data-report/BusinessReport/index.vue"),
      meta: {
        // icon: "data-report1",
        title: $t("menus.businessReport"),
        keepAlive: true,
        showLink: true,
        showParent: true,
        authority: ["acquirer.reportoper"]
      }
    },
    {
      path: "/data-report/ranking-list",
      name: "RankingList",
      component: () => import("/@/views/data-report/RankingList/index.vue"),
      meta: {
        // icon: "data-report2",
        title: $t("menus.rankingList"),
        keepAlive: true,
        showLink: true,
        showParent: true,
        authority: ["acquirer.reportrank"]
      }
    },
    {
      path: "/data-report/transaction-analysis",
      name: "TransactionAnalysis",
      component: () =>
        import("/@/views/data-report/TransactionAnalysis/index.vue"),
      meta: {
        // icon: "data-report3",
        title: $t("menus.transactionAnalysis"),
        keepAlive: true,
        showLink: true,
        showParent: true,
        authority: ["acquirer.trans.analysis"]
      }
    },
    {
      path: "/data-report/currency-analysis",
      name: "CurrencyAnalysis",
      component: () =>
        import("/@/views/data-report/CurrencyAnalysis/index.vue"),
      meta: {
        // icon: "data-report4",
        title: $t("menus.currencyAnalysis"),
        keepAlive: true,
        showLink: true,
        showParent: true,
        authority: ["acquirer.currency.analysis"]
      }
    }
  ]
};

export default homeRouter;
