import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const tagMgrRouter = {
  path: "/tag-mgr",
  component: Layout,
  redirect: "/system/index",
  meta: {
    icon: "system-setting",
    title: $t("menus.system"),
    rank: 10,
    authority: ["acquirer.recovery", "acquirer.label", "acquirer.operlog"]
  },
  children: [
    {
      path: "/system/index",
      name: "SystemSetting",
      component: () => import("/@/views/system/index.vue"),
      meta: {
        // icon: "system-setting",
        showParent: true,
        title: $t("systemSetting.pageTitle"),
        authority: ["acquirer.recovery"]
      }
    },
    {
      path: "/tag-mgr/tag-mgr",
      name: "TagMgr",
      component: () => import("/@/views/tag-mgr/index.vue"),
      meta: {
        // icon: "tag-mgr",
        title: $t("menus.tagMgr"),
        showParent: true,
        authority: ["acquirer.label"]
      }
    },
    {
      path: "/log/operation",
      name: "OperationLog",
      component: () => import("/@/views/log/operation/index.vue"),
      meta: {
        // icon: "log-admin",
        title: $t("log.operationLog"),
        authority: ["acquirer.operlog"],
        showParent: true
      }
    }
  ]
};

export default tagMgrRouter;
