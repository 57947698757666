// import { http } from "../views/channel-admin/ChannelAdmin/components/http";
import { http } from "../utils/http";

// 登录
export const login = (params?: object) => {
  return http.request("post", "/klond-acquirer-api/login", {
    params
  });
};

// 获取二维码链接
export const getCodeUrl = () => {
  return http.request("get", "/klond-acquirer-api/User/getDualAuthQrCode");
};

// 重置双重因子认证
export const resetCode = () => {
  return http.request("get", "/klond-acquirer-api/User/getDualAuthQrCode");
};
// 获取用户邮箱
export const getEmail = () => {
  return http.request("get", "/klond-acquirer-api/User/getCurrentUserEmail");
};
// 生成邮箱验证码
export const generateEmail = () => {
  return http.request("get", "/klond-acquirer-api/User/generateEmailCode");
};
// 验证邮箱验证码
export const verifyEmail = (params?: object) => {
  return http.request("get", "/klond-acquirer-api/User/verifyEmailCode", {
    params
  });
};

//检查认证因子CODE是否正确
export const checkAuthCode = (params?: object) => {
  return http.request("get", "/klond-acquirer-api/User/checkDualAuthCode", {
    params
  });
};

// 获取验证码图片
export const getLoginVerifyCode = () => {
  return http.request(
    "get",
    "/klond-acquirer-api/code/code/image",
    {},
    {},
    "blob"
  );
};

//退出登陆
export const quitOut = () => {
  return http.request("get", "/klond-acquirer-api/common/quit");
};
