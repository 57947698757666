import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const amtClearRouter = {
  path: "/amt-clearing",
  component: Layout,
  redirect: "/amt-clearing/index",
  meta: {
    icon: "settle1",
    title: $t("menus.report"),
    rank: 9,
    authority: [
      "acquirer.sttl.report.amount",
      "acquirer.sttl.report",
      "acquirer.sttl.total"
    ]
  },
  children: [
    {
      path: "/clearing/total",
      name: "ClearTotal",
      component: () => import("/@/views/clearing/collect.vue"),
      meta: {
        // icon: "settle1",
        title: $t("结算汇总"),
        authority: ["acquirer.sttl.total"],
        showParent: true,
        keepAlive: true
      }
    },
    {
      path: "/clearing/detail",
      name: "ClearDetail",
      component: () => import("/@/views/clearing/detail.vue"),
      meta: {
        // icon: "settle2",
        title: $t("结算明细"),
        authority: ["acquirer.sttl.report"],
        showParent: true,
        keepAlive: true
      }
    },
    {
      path: "/amt-clearing/index",
      name: "AmtAClearing",
      component: () => import("/@/views/amt-clearing/report/index.vue"),
      meta: {
        // icon: "amt-clearing",
        title: $t("资金结算报表"),
        authority: ["acquirer.sttl.report.amount"],
        showParent: true,
        keepAlive: true
      }
    }
  ]
};

export default amtClearRouter;
