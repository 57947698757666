import { computed } from "vue";
import { router } from "/@/router";
import { getConfig } from "/@/config";
import { emitter } from "/@/utils/mitt";
import { routeMetaType } from "../types";
import { remainingPaths } from "/@/router";
import { transformI18n } from "/@/plugins/i18n";
import { storageSession } from "/@/utils/storage";
import { useAppStoreHook } from "/@/store/modules/app";
import { useEpThemeStoreHook } from "/@/store/modules/epTheme";
import { useMerchantHook } from "/@/store/modules/merchant";
import { quitOut } from "/@/api/login";

const errorInfo = "当前路由配置不正确，请检查配置";

export function useNav() {
  const pureApp = useAppStoreHook();
  // 用户名
  const username: string = storageSession.getItem("info")?.username;
  const rolename: string = storageSession.getItem("info")?.rolename;

  // 设置国际化选中后的样式
  const getDropdownItemStyle = computed(() => {
    return (locale, t) => {
      return {
        background: locale === t ? useEpThemeStoreHook().epThemeColor : "",
        color: locale === t ? "#f4f4f5" : "#000"
      };
    };
  });

  // const avatarsStyle = computed(() => {
  //   return username ? { marginRight: "10px" } : "";
  // });

  const isCollapse = computed(() => {
    return !pureApp.getSidebarStatus;
  });

  // 动态title
  function changeTitle(meta: routeMetaType) {
    const Title = getConfig().Title;
    if (Title) document.title = `${transformI18n(meta.title)} | ${Title}`;
    else document.title = transformI18n(meta.title);
  }

  // 请求退出接口
  function logout() {
    quitOut().then(res => {
      logoutTimeOut();
    });
  }
  // 退出登录
  function logoutTimeOut() {
    storageSession.clear();
    localStorage.clear();
    useMerchantHook().SET_ClearTime1();
    useMerchantHook().SET_ClearTime2();
    router.push("/login");
  }
  //修改密码
  function changePassword(name) {
    storageSession.setItem("first", true);
    router.push({
      name: "UpdatePassword",
      query: { name: name, type: "timeout" }
    });
  }

  function backHome() {
    router.push("/data-board");
  }

  function onPanel() {
    emitter.emit("openPanel");
  }

  function toggleSideBar() {
    pureApp.toggleSideBar();
  }

  function handleResize(menuRef) {
    menuRef.handleResize();
  }

  function resolvePath(route) {
    if (!route.children) return console.error(errorInfo);
    const httpReg = /^http(s?):\/\//;
    const routeChildPath = route.children[0]?.path;
    if (httpReg.test(routeChildPath)) {
      return route.path + "/" + routeChildPath;
    } else {
      return routeChildPath;
    }
  }

  function menuSelect(indexPath: string, routers): void {
    if (isRemaining(indexPath)) return;
    let parentPath = "";
    const parentPathIndex = indexPath.lastIndexOf("/");
    if (parentPathIndex > 0) {
      parentPath = indexPath.slice(0, parentPathIndex);
    }
    // 找到当前路由的信息
    function findCurrentRoute(indexPath: string, routes) {
      if (!routes) return console.error(errorInfo);
      return routes.map(item => {
        if (item.path === indexPath) {
          if (item.redirect) {
            findCurrentRoute(item.redirect, item.children);
          } else {
            // 切换左侧菜单 通知标签页
            emitter.emit("changLayoutRoute", {
              indexPath,
              parentPath
            });
          }
        } else {
          if (item.children) findCurrentRoute(indexPath, item.children);
        }
      });
    }
    findCurrentRoute(indexPath, routers);
  }

  // 判断路径是否参与菜单
  function isRemaining(path: string): boolean {
    return remainingPaths.includes(path);
  }

  function filterTree(tree, arr = []) {
    if (!tree.length) return [];
    for (const item of tree) {
      // 循环数组，然后过滤数据
      // 如果该条数据type不为0时，跳出本次循环
      if (item.type !== 0) continue;
      // 如果满足条件时，用新node代替，然后把chilren清空
      const node = { ...item, children: [] };
      // 然后添加到新数组中
      arr.push(node);
      // 如果有子节点，调用递归函数，并把空数组传给下一个函数
      // 利用引用数据类型的特性，实现浅拷贝
      // 递归函数过滤时会改变这个空数组数据，从而实现层级结构过滤
      if (item.children && item.children.length) {
        filterTree(item.children, node.children);
      }
    }
    return arr;
  }

  // 遍历机构树：加全部选项
  const depthFirstTraversalRecursion = (root, arr = []) => {
    if (!root) return arr;
    const { children } = root;
    if (children) {
      children.splice(0, 0, {
        acquirerName: "全部",
        acquirerId: "all"
      });
      children.forEach(child => depthFirstTraversalRecursion(child, arr));
    }
    arr.push(root);
    return arr;
  };

  // 格式化机构树
  const formatTreeData = data => {
    let arr = [];
    if (!data) return arr;
    data.splice(0, 0, {
      acquirerName: "全部",
      acquirerId: "all"
    });
    data.some(v => {
      const a = depthFirstTraversalRecursion(v);
      console.log(a);
      arr = arr.concat(a);
    });
    return arr;
  };

  const checkAll = () => {};

  return {
    logout,
    logoutTimeOut,
    backHome,
    onPanel,
    changeTitle,
    toggleSideBar,
    menuSelect,
    handleResize,
    resolvePath,
    changePassword,
    isCollapse,
    pureApp,
    username,
    rolename,
    getDropdownItemStyle,
    filterTree,
    formatTreeData,
    checkAll
  };
}
