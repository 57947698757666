import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const transactionFlowRouter = {
  path: "/transaction-flow",
  component: Layout,
  redirect: "/transaction-flow/index",
  meta: {
    icon: "trans1",
    title: $t("menus.transaction"),
    rank: 7,
    authority: [
      "acquirer.trans",
      "acquirer.translog",
      "acquirer.acquirer.refund",
      "acquirer.acquirer.refundAudit"
    ]
  },
  children: [
    {
      path: "/transaction-flow/index",
      name: "TransactionFlow",
      component: () => import("/@/views/transaction-flow/index.vue"),
      meta: {
        // icon: "trans1",
        keepAlive: true,
        showParent: true,
        title: $t("transactionFlow.pageTitle"),
        authority: ["acquirer.trans"]
      }
    },
    {
      path: "/log/transaction",
      name: "TransactionLog",
      component: () => import("/@/views/log/transaction/index.vue"),
      meta: {
        // icon: "trans2",
        title: $t("log.transactionLog"),
        authority: ["acquirer.translog"],
        showParent: true
      }
    },
    {
      path: "/transaction-flow/ret-record",
      name: "retRecord",
      component: () => import("/@/views/ret-record/index.vue"),
      meta: {
        // icon: "trans3",
        title: $t("退货记录"),
        showParent: true,
        authority: ["acquirer.acquirer.refund"]
        // authority: ["acquirer.refund"]
      }
    },
    {
      path: "/transaction-flow/ret-record-visa",
      name: "retRecordVisa",
      component: () => import("/@/views/ret-record-visa/index.vue"),
      meta: {
        // icon: "trans4",
        title: $t("退款记录 (VISA)"),
        showParent: true,
        authority: ["acquirer.acquirer.refund"]
        // authority: ["acquirer.refund"]
      }
    },
    {
      path: "/transaction-flow/ret-audit",
      name: "retAudit",
      component: () => import("/@/views/ret-audit/AuditAdmin/index.vue"),
      meta: {
        // icon: "trans5",
        title: $t("退货审核"),
        showParent: true,
        authority: ["acquirer.acquirer.refundAudit"]
        // authority: ["acquirer.refundAudit"]
      }
    },
    {
      path: "/transaction-flow/ret-audit-visa",
      name: "retAuditVisa",
      component: () => import("/@/views/ret-audit-visa/AuditAdmin/index.vue"),
      meta: {
        // icon: "trans6",
        title: $t("退款审核 (VISA)"),
        showParent: true,
        authority: ["acquirer.acquirer.refundAudit"]
        // authority: ["acquirer.refundAudit"]
      }
    }
  ]
};

export default transactionFlowRouter;
