import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const merchantRouter = {
  path: "/merchant",
  component: Layout,
  redirect: "/merchant/merchantadmin",
  meta: {
    icon: "merchant-admin",
    title: $t("menus.merchant"),
    rank: 4,
    keepAlive: true,
    authority: ["acquirer.merchant", "acquirer.audit"]
  },
  children: [
    {
      path: "/merchant/merchantadmin",
      name: "merchantInfor",
      component: () =>
        import("/@/views/merchant-admin/MerchantAdmin/index.vue"),
      meta: {
        title: $t("menus.merchantTitle"),
        keepAlive: true,
        showParent: true,
        authority: ["acquirer.merchant"]
      }
    },
    {
      path: "/merchant/merchantrecycle",
      name: "merchantRecycle",
      component: () => import("/@/views/merchant-admin/RecycleMID/index.vue"),
      meta: {
        title: $t("menus.merchantRecycle"),
        showLink: false,
        keepAlive: true,
        authority: ["acquirer.merchant"]
      }
    },
    {
      path: "/merchant/merchantopera",
      name: "merchantOpera",
      component: () =>
        import("/@/views/merchant-admin/MerchantOpera/index.vue"),
      meta: {
        title: $t("merchant.merchantOpera"),
        showLink: false,
        keepAlive: true,
        authority: ["acquirer.merchant"]
      }
    },
    {
      path: "/merchant/merchantadd",
      name: "merchantAdd",
      component: () => import("/@/views/merchant-admin/MerchantAdd/index.vue"),
      meta: {
        title: $t("新增商户"),
        showLink: false,
        keepAlive: true,
        authority: ["acquirer.merchant"]
      }
    },
    {
      path: "/merchant/edit1",
      name: "merchantEdit1",
      component: () => import("/@/views/merchant-admin/MerchantEdit/index.vue"),
      meta: {
        title: $t("商户详情"),
        showLink: false,
        keepAlive: true,
        authority: ["acquirer.merchant"]
      }
    },
    {
      path: "/merchant/edit",
      name: "merchantEdit",
      component: () => import("/@/views/merchant-admin/MerchantEdit/index.vue"),
      meta: {
        title: $t("编辑商户"),
        showLink: false,
        keepAlive: true,
        authority: ["acquirer.merchant"]
      }
    },
    {
      path: "/merchant/addTerminal",
      name: "merchantTerminal",
      component: () =>
        import("/@/views/merchant-admin/MerchantAddTerminal/index.vue"),
      meta: {
        title: $t("menus.terminalMgrAdd"),
        showLink: false,
        keepAlive: true,
        authority: ["acquirer.merchant"]
      }
    },
    {
      path: "/audit-admin/audit-admin",
      name: "auditAdmin",
      component: () => import("/@/views/audit-admin/AuditAdmin/index.vue"),
      meta: {
        // icon: "audit-admin",
        title: $t("menus.auditAdmin"),
        keepAlive: true,
        showParent: true,
        authority: ["acquirer.audit"]
      }
    },
    {
      path: "/audit-admin/audit-detail",
      name: "auditDetail",
      component: () => import("/@/views/audit-admin/AuditDetail/index.vue"),
      meta: {
        title: $t("审核详情"),
        showLink: false,
        authority: ["acquirer.audit"]
      }
    }
  ]
};

export default merchantRouter;
